// export function pageFlowWithTokenRoutePathGenerator() {
//   return `/page-flow/with-token`;
// }

import { Router } from '@angular/router';
import { v7 } from 'uuid';
import { RouteInfos } from '@camino-solutions/core/router';

export function pageFlowFlowIdRoutePathGenerator(flow: string) {
  return `/page-flow/${flow}`;
}

export function pageFlowTokenRoutePathGenerator() {
  return `/page-flow`;
}

export function pageFlowFromCardRoutePathGenerator() {
  return `/page-flow/from-card`;
}

// export function pageFlowModifyRoutePathGenerator(token: string) {
//   return `/page-flow/${token}`;
// }

// command: () =>
//   void inject(Router).navigate([pageFlowFlowIdRoutePathGenerator('company')], {
//     info: { fakeToken: { type: 'string', value: v7() } } satisfies RouteInfos,
//   }),

export function pageFlowGoToByFlowId(flowId: string, router: Router) {
  void router.navigate([pageFlowFlowIdRoutePathGenerator(flowId)], {
    info: { fakeToken: { type: 'string', value: v7() } } satisfies RouteInfos,
  });
}
